import React from "react";
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

// Import Utilities and Style Elements
import { BLOCKS, MARKS } from '@contentful/rich-text-types'

const Bold = ({ children }) => <span className="bold">{children}</span>
const Text = ({ children }) => <p className="align-center">{children}</p>

// Immagine in richText
const options = {
    renderMark: {
        [MARKS.BOLD]: text => <Bold>{text}</Bold>,
      },
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
        [BLOCKS.EMBEDDED_ASSET]: node => {
        const image = getImage(node.data.target)
        return <GatsbyImage image={image} alt={node.title} />
        },
    },
}

export default options;