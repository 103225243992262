import * as React from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { ParallaxProvider, Parallax } from 'react-scroll-parallax';

import { renderRichText } from "gatsby-source-contentful/rich-text"
import options from "../components/utils/richText"

import Layout from "../components/layout"



const EventoPageTemplate = ({data, location}) => {
  const evento = data.contentfulEvento

  return (
    <Layout location={location} title=''>
    <ParallaxProvider>

      <section className="pb-40 container max-w-screen-2xl">
        <Parallax y={[-20, 60]} tagOuter="div" className="relative z-20">
          <h1 className="text-6xl md:text-8xl p-20">{evento.titolo}</h1>
        </Parallax>
      <div className="grid md:grid-cols-2 gap-16 mx-10">
        <div>
            <Parallax y={[30, -10]} tagOuter="div" className="z-10">
              <GatsbyImage 
              image={evento.coverImage.gatsbyImageData}
              alt={evento.titolo}
              itemProp="image"
              className="mb-20 shadow-2xl"
              />
            </Parallax>
            <div className="text-center sticky top-0">
                  {evento.tipologiaEvento && evento.tipologiaEvento.map(tipo => {
                    return (
                        <h2>
                         {tipo.titolo}
                        </h2>
                    )
                })}
                {evento.data && 
                    <div className="py-5 text-2xl font-bold">
                        {evento.data}
                    </div>
                }
                {evento.limiteIscrizioni &&
                    <div className="py-5 text-2xl font-bold">
                    Limite partecipanti: {evento.limiteIscrizioni}
                    </div>
                }
                {evento.prezzo && 
                    <div className="py-5 text-2xl font-bold">
                        Costo: €{evento.prezzo}
                    </div>
                }
                {evento.prezzoEarlyBirds && 
                <div>
                    <div className="py-5 text-xl font-bold text-white">
                        <span className="border-green_primary border-4 bg-gradient-to-r from-green_primary to-green_secondary py-4 px-12">Costo early birds: €{evento.prezzoEarlyBirds}</span>
                    </div>
                    <small>Disponibile fino al {evento.earlyBirdDate}</small>
                </div>
                }

                {evento.quotaIscrizione ? (
                  <div className="py-5 text-md">
                    La quota associativa per l'anno {new Date().getFullYear()},<br /> per chi non fosse già associato, è di 20€
                  </div>
                ):(
                  <></>
                )}

                <a href={evento.linkButton} target="_blank" rel="noreferrer">
                  <button className="text-black">Partecipa</button>
                </a>
                <div className="py-5">
                  <small>Per ulteriori informazioni contattaci</small>
                </div>
            </div>
        </div>
        <div className="grid grid-cols-1">
          {evento.descrizione &&
          <div className="content md:px-5 px-10 md:pt-60">
          <h2 className="sticky top-0 bg-white">Descrizione</h2>
              {renderRichText(evento.descrizione,options)}
          </div>
          }
          {evento.programma &&
          <div className="content md:px-5 px-10 md:pt-40">
          <h2 className="sticky top-0 bg-white">Programma</h2>
              {renderRichText(evento.programma,options)}
          </div>
          }
          {evento.bio &&
          <div className="content md:px-5 px-10 md:pt-40">
          <h2 className="sticky top-0 bg-white">Bio</h2>
              {renderRichText(evento.bio,options)}
          </div>
          }
          {evento.requisiti &&
          <div className="content md:px-5 px-10 md:pt-40">
          <h2 className="sticky top-0 bg-white">Requisiti</h2>
            {renderRichText(evento.requisiti,options)}
          </div>
          }
          {evento.note &&
          <div className="content md:px-5 px-10 md:pt-40">
          <h2 className="sticky top-0 bg-white">Note</h2>
              {renderRichText(evento.note,options)}
          </div>
          }
        </div>
      </div>
      </section>
  </ParallaxProvider>
    </Layout>
  )
}

export default EventoPageTemplate

export const eventoTemplateQuery = graphql`
  query EventoBySlug($id: String!) {
    contentfulEvento(id: {eq: $id}) {
        titolo
        slug
        prezzo
        prezzoEarlyBirds
        earlyBirdDate(formatString: "DD/MM/YY")
        data
        linkButton
        limiteIscrizioni
        quotaIscrizione
        tipologiaEvento {
            titolo
        }
        descrizione {
            raw
        }
        programma {
          raw
        }
        bio {
          raw
        }
        requisiti {
          raw
        }
        note {
          raw
        }
        coverImage {
            gatsbyImageData(
              quality: 80
              layout: FULL_WIDTH
              placeholder: DOMINANT_COLOR
              formats: [AUTO, WEBP]
              aspectRatio: 1
            )
          }
    }
  }
`
